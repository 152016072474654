import { Box } from '@mui/material';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import Navbar from './components/Navbar/index';
import {UsersPage} from './pages/UsersPage';
import {SettingsPage} from './pages/SettingsPage';
import ResponsiveAppBar from './components/AppBar/index';
import { HomePage } from './pages/HomePage';
import TelegramLoginButton from 'telegram-login-button'
import useAuth from './hooks/AuthHook'
import { SitePage } from './pages/SitesPage';

export const Routing = () => {
  const { user, login } = useAuth()
  return (
    <BrowserRouter>
      {user?.isAuthenticated ? 
          <Box
            sx={{
              display: 'grid',
              height: '100%',
              gridTemplateColumns: 'repeat(5, 1fr)',
              gap: 3,
              gridTemplateRows: 'auto 1fr min-content',
              gridTemplateAreas: `
                "header header header header header"
                "sidebar main main main main"
                "footer footer footer footer footer"
              `,
            }}
          >
            <Box sx={{ gridArea: 'header' }}>
              <ResponsiveAppBar/>
            </Box>
            <Box sx={{ gridArea: 'main', overflow: 'auto'}}>
              <Routes>
                <Route path='/' element={<HomePage/>}></Route>
                <Route path='/users' exact element={<UsersPage/>}></Route>
                <Route path='/settings' element={<SettingsPage/>}></Route>
                <Route path='/sites' element={<SitePage/>}></Route>
              </Routes>
            </Box>
            <Box sx={{ gridArea: 'sidebar' }}>
              <Navbar/>
            </Box>
            <Box sx={{ gridArea: 'footer', display: 'flex', alignItems: 'center', px: '20px' }}>
              <span style={{fontSize: '12px'}}>Tradebot v1.0</span>
              <Box sx={{ width: 80, ml: 'auto', p:1 }}><a href='https://vlasovdev.store/'><img src="https://vlasovdev.store/logo.svg"/></a></Box>
            </Box>
          </Box>
         : 
        <Box sx={{ height: '100%', display: 'grid', alignItems: 'center', justifyContent: 'center'}}>
          <TelegramLoginButton dataOnauth={async (res) => await login(res)} botName={'VlasovApiBot'} />
        </Box> 
        }
    </BrowserRouter>
  )
}
import * as React from 'react';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { useLocation } from 'react-router';
import { GearIcon, HomeIcon, PeopleIcon, } from '@primer/octicons-react'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { Link } from 'react-router-dom';

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const Tab = styled(TabUnstyled)(({theme}) => `
color: white;
cursor: pointer;
font-size: 0.875rem;
text-decoration: none;
display: grid;
align-items: center;
gap: 10px;
font-weight: bold;
background-color: transparent;
padding: 12px 16px;
margin: 6px 6px;
border: none;
border-radius: 5px;
display: flex;
justify-content: flex-start;

&:hover {
  background-color: ${blue[400]};
}
&:focus {
  color: #fff;
  border-radius: 3px;
  outline: 2px solid ${blue[200]};
  outline-offset: 2px;
}

&.${tabUnstyledClasses.selected} {
  background-color: ${blue[50]};
  color: ${blue[600]};
  transition-duration: .2s;
}

&.${buttonUnstyledClasses.disabled} {
  opacity: 0.5;
  cursor: not-allowed;
}
`);

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(({theme}) => `
min-width: 320px;
background-color: ${theme.palette.primary.main};
border-radius: 8px;
height: 100%;
display: flex;
flex-flow: column;
align-items: stretch;
justify-content: flex-start;
align-content: space-between;
`);

export default function Navbar() {
  const {pathname} = useLocation()
  const tabs = {
    '/': 0,
    '/users': 1,
    '/settings': 2,
    '/sites': 3,
  }
  return (
    <TabsUnstyled style={{height: '100%'}} defaultValue={tabs[pathname]}>
        <TabsList>
          <Tab label="Home" index={0} component={Link} to={"/"}><HomeIcon/> Home</Tab>
          <Tab label="Users" index={1} component={Link} to={"/users"}><PeopleIcon/> Users</Tab>
          <Tab label="Settings" index={2} component={Link} to={"/settings"}><GearIcon/> Settings</Tab>
          <Tab label="Sites" index={3} component={Link} to={"/sites"}><GearIcon/> Sites</Tab>
        </TabsList>
    </TabsUnstyled>
  );
}
import React from 'react';
import { ThemeProvider } from '@mui/system';
import { CssBaseline } from "@mui/material";
import './App.css';
import { ProvideAuth } from './hooks/AuthHook';
import { defaultTheme } from './themes';
import { Routing } from './Routing';

function App() {
  return (
    <div className="App">
      <ProvideAuth>
        <ThemeProvider theme={defaultTheme}> 
          <CssBaseline />
          <Routing/> 
        </ThemeProvider>
      </ProvideAuth>
    </div>
  );
}
export default App;

import CustomInput from '../components/Input';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CustomButton from '../components/Button';

export const SettingsPage = () => {
  return(
    <Paper sx={{ p: 3, height: '100%', overflow: 'auto'}}>
      <Box display={'grid'} gap={'20px'}>
        <Typography letterSpacing={'-0.05em'} variant="h4"><strong>Settings</strong></Typography>
        <Typography letterSpacing={'-0.05em'} variant="h5"><strong>Visual</strong></Typography>
        <Box sx={{display: 'grid', gap: '20px', gridTemplateColumns: '300px 300px'}}>
          <CustomInput label={'Logo'} placeholder={'Logo URL'} type="text"/>
          <CustomInput label={'Favicon'} placeholder={'Favicon URL'} type="text"/>
          <CustomInput label={'Name'} placeholder={'Site name'} type="text"/>
          <CustomInput label={'URL'} placeholder={'Site URL'} type="text"/>
          <CustomInput label={'Tab title'} placeholder={'Tab title'} type="text"/>
        </Box>
        <Typography letterSpacing={'-0.05em'} variant="h5"><strong>API config</strong></Typography>
        <Box sx={{display: 'grid', gap: '20px', gridTemplateColumns: '300px 300px'}}>
          <CustomInput label={'Items multiplier'} placeholder={'1 - default'} type="number"/>
          <CustomInput label={'Inventory multiplier'} placeholder={'1 - default'} type="number"/>
        </Box>
        <CustomButton sx={{alignSelf: 'flex-end'}}>Save</CustomButton>
    </Box>
    </Paper>
  )
}
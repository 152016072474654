import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CustomButton from '../components/Button';
import CustomInput from '../components/Input';
import { newSite } from '../api';

export const SitePage = () => {
  const [formData, setFormData] = useState(
    {
      ip: '',
      hostname: '',
      name: ''
    })
  const changeForm = (val, name) => {
    setFormData(prevState => ({...prevState, [name]: val}))
  }
  const action = async () => {
    await newSite(formData);
  }
  return(
    <Paper sx={{ p: 3, height: '100%', overflow: 'auto'}}>
      <Box display={'grid'} gap={'20px'}>
        <Typography letterSpacing={'-0.05em'} variant="h4"><strong>Sites</strong></Typography>
        <Typography letterSpacing={'-0.05em'} variant="h5"><strong>New site</strong></Typography>
        <Box sx={{display: 'grid', gap: '20px', gridTemplateColumns: '300px 300px'}}>
          <CustomInput onChange={(e) => changeForm(e.target.value, 'ip')} value={formData.ip} label={'Server IP'} name="ip" placeholder={'Server IP'} type="text"/>
          <CustomInput onChange={(e) => changeForm(e.target.value, 'hostname')} value={formData.hostname} label={'Domain'} name="hostname" placeholder={'Domain'} type="text"/>
          <CustomInput onChange={(e) => changeForm(e.target.value, 'name')} value={formData.name} label={'Name'} name="name" placeholder={'Site name'} type="text"/>
        </Box>
        <CustomButton onClick={action} sx={{alignSelf: 'flex-end'}}>Add</CustomButton>
    </Box>
    </Paper>
  )
}
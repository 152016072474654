import {Paper, Box, Chip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CheckCircleIcon, XCircleIcon } from '@primer/octicons-react';

export const HomePage = () => {
  return (
    <Paper sx={{ height: '100%', p: 3}}>
      <Box display={'grid'} gap={'20px'}>
          <Typography letterSpacing={'-0.05em'} variant="h4"><strong>Welcome.</strong></Typography>
          <Box sx={{display: 'grid', gridAutoFlow: 'column', gap: 2}}>
            <Typography letterSpacing={'-0.05em'} variant="p"><span>You use unlimited custom plan API</span></Typography>
            <Box sx={{display: 'grid', gridAutoColumns: 'max-content', gap: 2}}>
              <Typography letterSpacing={'-0.05em'} variant="p"><span>Access:</span></Typography>
              <Chip color="success" label="Auth-system" icon={<CheckCircleIcon />} />
              <Chip color="success" label="Inventories" icon={<CheckCircleIcon />} />
              <Chip color="success" label="Users list" icon={<CheckCircleIcon />} />
              <Chip color="success" label="Price changer" icon={<CheckCircleIcon />} />
              <Chip color="success" label="Template options" icon={<CheckCircleIcon />} />
            </Box>
            <Box sx={{display: 'grid', gridAutoColumns: 'max-content', gap: 2}}>
              <Typography letterSpacing={'-0.05em'} variant="p"><strong>For $100 per month you can get:</strong></Typography>
              <Chip color="error" label="Template builder" icon={<XCircleIcon />} />
              <Chip color="error" label="Template themes" icon={<XCircleIcon />} />
              <Chip color="error" label="Unlimited sites" icon={<XCircleIcon />} />
              <Chip color="error" label="Access for workers (Template options)" icon={<XCircleIcon />} />
              <Chip color="error" label="Unlimited templates" icon={<XCircleIcon />} />
              <Chip color="error" label="Multipage templates" icon={<XCircleIcon />} />
              <Chip color="error" label="Online users list" icon={<XCircleIcon />} />
              <Chip color="error" label="Realtime alerts to user" icon={<XCircleIcon />} />
              <Chip color="error" label="Unique algorithms for login" icon={<XCircleIcon />} />
              <Chip color="error" label="Free updates and support" icon={<XCircleIcon />} />
            </Box>
          </Box>
      </Box>
    </Paper>
  )
}
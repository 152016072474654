import React, {useState} from 'react';
import {Avatar, Paper} from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}



export const UsersPage = () => {
  const columns = [
    {
      field: 'avatar',
      headerName: 'Avatar',
      width: 90,
      renderCell: (params) => <Avatar src={params.row.avatar} />,
    },
    {
      field: 'displayName',
      headerName: 'Nickname',
      width: 150,
      editable: false,
    },
    {
      field: 'id',
      headerName: 'SteamId',
      width: 150,
      editable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      sortable: true,
      width: 160,
    }
  ];
  
  const rows = [
    { avatar: 'https://avatars.akamai.steamstatic.com/98a005655a4b852270fc7b9f7b904cfa20e1c345_full.jpg', id: 'Snow', displayName: 'Jon', createdAt: new Date() },
  ];
    return(<Paper sx={{height: '100%'}}>
    <DataGrid
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      </Paper>)
}